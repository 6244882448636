import apiClient from 'services/axios'
// import store from 'store'

export async function getAll(limit, offset, query, status) {
  let url = `/jobs?limit=${limit}&offset=${offset}`
  url += query ? `&conditions=query,like,${query}` : ''
  url += status ? `&conditions=jobs.state,eq,${status}` : ''

  return apiClient
    .get(url)
    .then(response => {
      if (response) {
        return {
          list: response.data,
          total: response.headers['content-range']?.split('/')[1],
        }
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function get(id) {
  return apiClient
    .get(`/jobs/${id}`)
    .then(response => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function remove(id) {
  return apiClient
    .delete(`/jobs/${id}`)
    .then(response => {
      if (response && response.status === 202) {
        return true
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function approve(id) {
  return apiClient
    .put(`/jobs/${id}/approve`)
    .then(response => {
      if (response && response.status === 202) {
        return true
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function reject({ id, message }) {
  return apiClient
    .put(`/jobs/${id}/reject`, { rejection_message: message })
    .then(response => {
      if (response && response.status === 202) {
        return true
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function disable(id) {
  return apiClient
    .put(`/jobs/${id}/disable`)
    .then(response => {
      if (response && response.status === 202) {
        return true
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function enable(id) {
  return apiClient
    .put(`/jobs/${id}/enable`)
    .then(response => {
      if (response && response.status === 202) {
        return true
      }
      return false
    })
    .catch(err => console.log(err))
}

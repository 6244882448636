import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import user from './user/reducers'
import menu from './menu/reducers'
import settings from './settings/reducers'
import jobs from './jobs/reducers'
import companies from './companies/reducers'
import users from './users/reducers'
import profile from './profile/reducers'
import occupations from './occupations/reducers'
import industries from './industries/reducers'
import legalForms from './legalForms/reducers'
import roles from './roles/reducers'
import careers from './careers/reducers'
import educations from './educations/reducers'
import employmentTypes from './employmentTypes/reducers'
import languages from './languages/reducers'
import jobStates from './jobStates/reducers'

export default history =>
  combineReducers({
    router: connectRouter(history),
    user,
    menu,
    settings,
    jobs,
    companies,
    users,
    profile,
    occupations,
    industries,
    legalForms,
    roles,
    careers,
    educations,
    employmentTypes,
    languages,
    jobStates,
  })

import { all, takeEvery, put, call } from 'redux-saga/effects'
// import { notification } from 'antd'
// import { history } from 'index'
import * as categories from 'services/categories'
import actions from './actions'

export function* GET_ALL() {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
      list: null,
    },
  })

  const response = yield call(categories.getAll, 'career')

  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        loading: false,
        list: response,
      },
    })
  } else {
    yield put({
      type: actions.SET_STATE,
      payload: {
        loading: false,
      },
    })
  }
}

/* export function* GET({ payload }) {
  const { id } = payload

  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
      single: null,
    },
  })

  const response = yield call(categories.get, id)

  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        loading: false,
        single: response,
      },
    })
  } else {
    yield put({
      type: actions.SET_STATE,
      payload: {
        loading: false,
      },
    })
  }
}

export function* ADD({ payload }) {
  const { data } = payload
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })

  const response = yield call(categories.add, { data })

  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        loading: false,
      },
    })

    notification.success({
      message: 'Félicitation',
      description: "Votre offre d'emploi a été ajoutée avec succès",
    })

    yield put({ type: actions.GET_ALL })
    history.push('/offres')
  } else {
    yield put({
      type: actions.SET_STATE,
      payload: {
        loading: false,
      },
    })
  }
}

export function* UPDATE({ payload }) {
  const { id, values } = payload
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })

  const response = yield call(categories.update, id, { values })

  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        loading: false,
      },
    })

    notification.success({
      message: 'Félicitation',
      description: "Votre offre d'emploi a été modifiée avec succès",
    })

    yield put({ type: actions.GET_ALL })
    history.push('/offres')
  } else {
    yield put({
      type: actions.SET_STATE,
      payload: {
        loading: false,
      },
    })
  }
}

export function* DELETE({ payload }) {
  const { id } = payload
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })

  const response = yield call(categories.remove, id)

  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        loading: false,
      },
    })

    notification.success({
      message: 'Félicitation',
      description: "Votre offre d'emploi a été suprimée avec succès",
    })

    yield put({ type: actions.GET_ALL })
    history.push('/offres')
  } else {
    yield put({
      type: actions.SET_STATE,
      payload: {
        loading: false,
      },
    })
  }
} */

export default function* rootSaga() {
  yield all([
    GET_ALL(),
    takeEvery(actions.GET_ALL, GET_ALL),
    // takeEvery(actions.GET, GET),
    // takeEvery(actions.ADD, ADD),
    // takeEvery(actions.UPDATE, UPDATE),
    // takeEvery(actions.DELETE, DELETE),
  ])
}

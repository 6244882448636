import { all, takeEvery, put, call, select } from 'redux-saga/effects'
import { notification } from 'antd'
import { history } from 'index'
import * as jobs from 'services/jobs'
import actions from './actions'

const getIndustry = (state, id) => {
  return state.industries.list.find(item => item.id === id)
}

const getCareer = (state, id) => {
  return state.careers.list.find(item => item.id === id)
}

const getEmploymentType = (state, value) => {
  return state.employmentTypes.list.find(item => item.value === value)
}

const getEducationLevel = (state, id) => {
  return state.educations.list.find(item => item.id === id)
}

const getOccupation = (state, id) => {
  return state.occupations.list.find(item => item.id === id)
}

const getLanguagesList = state => {
  return state.languages.list
}

export function* GET_ALL({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
      list: null,
    },
  })

  const { pagination = { current: 1, pageSize: 9999 }, query, status } = payload
  const { current, pageSize } = pagination
  const limit = pageSize
  const offset = (current - 1) * pageSize

  const response = yield call(jobs.getAll, limit, offset, query, status)

  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        loading: false,
        list: response.list,
        total: parseInt(response.total, 10),
      },
    })
  } else {
    yield put({
      type: actions.SET_STATE,
      payload: {
        loading: false,
      },
    })
  }
}

export function* GET({ payload }) {
  const { id } = payload

  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
      single: null,
    },
  })

  const response = yield call(jobs.get, id)

  const languagesList = yield select(getLanguagesList)

  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        loading: false,
        single: {
          ...response,
          industry: yield select(getIndustry, response.industry?.id),
          career_level: yield select(getCareer, response.career_level?.id),
          employment_type: yield select(getEmploymentType, response.employment_type),
          education_level: yield select(getEducationLevel, response.education_level?.id),
          languages: response.languages.map(languageId =>
            languagesList?.find(language => language.id === languageId),
          ),
          occupational_category: yield select(getOccupation, response.occupational_category?.id),
        },
      },
    })
  } else {
    yield put({
      type: actions.SET_STATE,
      payload: {
        loading: false,
      },
    })
  }
}

export function* DELETE({ payload }) {
  const { id, pagination, query, status } = payload
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })

  const response = yield call(jobs.remove, id)

  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        loading: false,
      },
    })

    notification.success({
      message: 'Félicitation',
      description: "Votre offre d'emploi a été suprimée avec succès",
    })

    yield put({
      type: actions.GET_ALL,
      payload: { pagination, query, status },
    })
    // history.push('/offres')
  } else {
    yield put({
      type: actions.SET_STATE,
      payload: {
        loading: false,
      },
    })
  }
}

export function* APPROVE({ payload }) {
  const { id } = payload
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })

  const response = yield call(jobs.approve, id)

  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        loading: false,
      },
    })

    history.push('/offres')
    notification.success({
      message: 'Félicitation',
      description: "L'offre d'emploi a été approuvée avec succès",
    })
  } else {
    yield put({
      type: actions.SET_STATE,
      payload: {
        loading: false,
      },
    })
  }
}

export function* REJECT({ payload }) {
  const { id, message } = payload
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })

  const response = yield call(jobs.reject, { id, message })

  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        loading: false,
      },
    })

    history.push('/offres')
    notification.success({
      message: 'Félicitation',
      description: "L'offre d'emploi a été declinée avec succès",
    })
  } else {
    yield put({
      type: actions.SET_STATE,
      payload: {
        loading: false,
      },
    })
  }
}

export function* DISABLE({ payload }) {
  const { id } = payload
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })

  const response = yield call(jobs.disable, id)

  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        loading: false,
      },
    })

    history.push('/offres')
    notification.success({
      message: 'Félicitation',
      description: "L'offre d'emploi a été desactivée avec succès",
    })
  } else {
    yield put({
      type: actions.SET_STATE,
      payload: {
        loading: false,
      },
    })
  }
}

export function* ENABLE({ payload }) {
  const { id } = payload
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })

  const response = yield call(jobs.enable, id)

  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        loading: false,
      },
    })

    history.push('/offres')
    notification.success({
      message: 'Félicitation',
      description: "L'offre d'emploi a été activée avec succès",
    })
  } else {
    yield put({
      type: actions.SET_STATE,
      payload: {
        loading: false,
      },
    })
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.GET_ALL, GET_ALL),
    takeEvery(actions.GET, GET),
    takeEvery(actions.DELETE, DELETE),
    takeEvery(actions.APPROVE, APPROVE),
    takeEvery(actions.REJECT, REJECT),
    takeEvery(actions.DISABLE, DISABLE),
    takeEvery(actions.ENABLE, ENABLE),
  ])
}

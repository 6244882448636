const actions = {
  SET_STATE: 'jobs/SET_STATE',
  GET_ALL: 'jobs/GET_ALL',
  GET: 'jobs/GET',
  DELETE: 'jobs/DELETE',
  APPROVE: 'jobs/APPROVE',
  REJECT: 'jobs/REJECT',
  DISABLE: 'jobs/DISABLE',
  ENABLE: 'jobs/ENABLE',
}

export default actions

import apiClient from 'services/axios'
// import store from 'store'

// eslint-disable-next-line import/prefer-default-export
export async function getAll(extension) {
  return apiClient
    .get(`/categories?extension=${extension}`)
    .then(response => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}

// export async function get(id) {
//   return apiClient
//     .get(`/categories/${id}`)
//     .then(response => {
//       if (response) {
//         return response.data
//       }
//       return false
//     })
//     .catch(err => console.log(err))
// }

// export async function add(data) {
//   return apiClient
//     .post('/categories', data)
//     .then(response => {
//       if (response && response.status === 202) {
//         return true
//       }
//       return false
//     })
//     .catch(err => console.log(err))
// }

// export async function update(id, data) {
//   return apiClient
//     .put(`/categories/${id}`, data)
//     .then(response => {
//       if (response) {
//         return response.data
//       }
//       return false
//     })
//     .catch(err => console.log(err))
// }

// export async function remove(id) {
//   return apiClient
//     .delete(`/categories/${id}`)
//     .then(response => {
//       if (response && response.status === 202) {
//         return true
//       }
//       return false
//     })
//     .catch(err => console.log(err))
// }

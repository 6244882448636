import apiClient from 'services/axios'
// import store from 'store'

export async function getAll(limit, offset) {
  const url = `/companies?limit=${limit}&offset=${offset}`

  return apiClient
    .get(url)
    .then(response => {
      if (response) {
        return {
          list: response.data,
          total: response.headers['content-range']?.split('/')[1],
        }
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function get(id) {
  return apiClient
    .get(`/companies/${id}`)
    .then(response => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function add(data) {
  return apiClient
    .post('/companies', data)
    .then(response => {
      if (response && response.status === 202) {
        return true
      }
      return false
    })
    .catch(err => {
      const errors = {}
      err.errors.forEach(error => {
        errors[error.name] = error.reason
      })
      return { errors }
    })
}

export async function update(id, data) {
  return apiClient
    .put(`/companies/${id}`, data)
    .then(response => {
      if (response && response.status === 202) {
        return true
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function remove(id) {
  return apiClient
    .delete(`/companies/${id}`)
    .then(response => {
      if (response && response.status === 202) {
        return true
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function approve(id) {
  return apiClient
    .put(`/companies/${id}/approve`)
    .then(response => {
      if (response && response.status === 202) {
        return true
      }
      return false
    })
    .catch(err => console.log(err))
}

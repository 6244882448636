const actions = {
  SET_STATE: 'legalForms/SET_STATE',
  GET_ALL: 'legalForms/GET_ALL',
  // GET: 'employmentTypes/GET',
  // ADD: 'employmentTypes/ADD',
  // UPDATE: 'employmentTypes/UPDATE',
  // DELETE: 'employmentTypes/DELETE',
}

export default actions

const actions = {
  SET_STATE: 'users/SET_STATE',
  GET_ALL: 'users/GET_ALL',
  GET: 'users/GET',
  ADD: 'users/ADD',
  UPDATE: 'users/UPDATE',
  DELETE: 'users/DELETE',
}

export default actions

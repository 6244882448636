import apiClient from 'services/axios'
// import store from 'store'

export async function getAll(limit, offset, role) {
  let url = `/users?limit=${limit}&offset=${offset}`
  url += role ? `&conditions=users.roles,eq,${role}` : ''

  return apiClient
    .get(url)
    .then(response => {
      if (response) {
        return {
          list: response.data,
          total: response.headers['content-range']?.split('/')[1],
        }
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function get(id) {
  return apiClient
    .get(`/users/${id}`)
    .then(response => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function add(data) {
  return apiClient
    .post('/users', data)
    .then(response => {
      if (response && response.status === 202) {
        return true
      }
      return false
    })
    .catch(err => {
      const errors = {}
      err.errors.forEach(error => {
        errors[error.name] = error.reason
      })
      return { errors }
    })
}

export async function update(id, data) {
  return apiClient
    .put(`/users/${id}`, data)
    .then(response => {
      if (response && response.status === 202) {
        return true
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function remove(id) {
  return apiClient
    .delete(`/users/${id}`)
    .then(response => {
      if (response && response.status === 202) {
        return true
      }
      return false
    })
    .catch(err => console.log(err))
}

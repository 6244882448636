const actions = {
  SET_STATE: 'companies/SET_STATE',
  GET_ALL: 'companies/GET_ALL',
  GET: 'companies/GET',
  ADD: 'companies/ADD',
  UPDATE: 'companies/UPDATE',
  DELETE: 'companies/DELETE',
  APPROVE: 'companies/APPROVE',
}

export default actions

import { all } from 'redux-saga/effects'
import user from './user/sagas'
import menu from './menu/sagas'
import settings from './settings/sagas'
import jobs from './jobs/sagas'
import companies from './companies/sagas'
import users from './users/sagas'
import profile from './profile/sagas'
import occupations from './occupations/sagas'
import industries from './industries/sagas'
import legalForms from './legalForms/sagas'
import roles from './roles/sagas'
import careers from './careers/sagas'
import educations from './educations/sagas'
import employmentTypes from './employmentTypes/sagas'
import languages from './languages/sagas'
import jobStates from './jobStates/sagas'

export default function* rootSaga() {
  yield all([
    user(),
    menu(),
    settings(),
    jobs(),
    companies(),
    users(),
    profile(),
    occupations(),
    industries(),
    legalForms(),
    roles(),
    careers(),
    educations(),
    employmentTypes(),
    languages(),
    jobStates(),
  ])
}
